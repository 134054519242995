import React, { useState, useEffect, useCallback } from 'react'
import { TourStatus, useCreateTourMutation, useDeleteTourMutation, useTourQuery, useUpdateTourMutation } from '@typings/graphql'
import { useNavigate, useParams } from 'react-router'
import { Box, Button, Chip, InputLabel, LinearProgress, MenuItem, Select, TextField, Typography } from '@mui/material'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormFooterBar } from '@features/cms/components/ui/FormFooterBar'
import { convertToTextObject } from '@utils/texts'
import { ContentFile, uploadFile } from '@features/cms/components/content-type-editors/mutation-helper/file'
import FileDragDrop from '@features/cms/components/FileDragDrop'
import { MarkdownEditor } from '@features/cms/components/MarkdownEditor'
import { ConfirmDialog } from '@features/cms/components/dialogs/ConfirmDialog'
import DeleteIcon from '@mui/icons-material/Delete'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { LoadingButton } from '@mui/lab'
import { cache } from '@services/apollo/cache'
import { MediaInfos } from '@features/cms/components/content-type-editors/components/MediaInfos'
import { useUnsavedChangesAlert } from '@hooks/useUnsavedChangesAlert'

const tourTextKeys = ['title', 'subtitle', 'description']

type TourFormInput = {
  price: string | number,
  status: TourStatus.Draft | TourStatus.Published | TourStatus.Private,
  texts: {
    title: string,
    subtitle: string,
    description: string
  },
  keyvisual: File | ContentFile | undefined | null,
}

const TourEdit: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [uploadError, setUploadError] = useState<any>()
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const [createTour, { loading: isCreating }] = useCreateTourMutation()
  const [updateTour, { loading: isUpdating }] = useUpdateTourMutation()
  const [deleteTour, { loading: isDeleting }] = useDeleteTourMutation()

  const { id } = useParams()
  const { data, refetch, loading } = useTourQuery({ variables: { id: id as string }, skip: !id })
  const tour = data?.tour

  const getDefaultValues = () => ({
    price: tour?.price || 0,
    status: tour?.status || TourStatus.Draft,
    texts: tour?.texts || tourTextKeys.reduce((acc, key) => ({ ...acc, [key]: '' }), {}),
    keyvisual: tour?.keyvisual || null
  })

  const methods = useForm<TourFormInput>({
    defaultValues: getDefaultValues()
  })

  console.log('methods', methods.getValues().keyvisual)

  useUnsavedChangesAlert(methods.formState.isDirty)

  const openTourDownloadUrl = useCallback(() => {
    if (!tour) {
      return
    }

    const url = `${import.meta.env.REACT_APP_API_ENDPOINT}/file/tour/export/${tour.id}`
    window.open(url, '_blank')
  }, [tour])

  const onSubmit = async (submittedData: TourFormInput) => {
    const texts = Object.keys(submittedData.texts)
      .map((key) => convertToTextObject(key, submittedData.texts[key as keyof typeof submittedData.texts]))

    let tourId = tour?.id

    if (tourId) {
      try {
        await updateTour({
          variables: {
            data: {
              price: parseFloat(submittedData.price as any),
              status: submittedData.status,
              texts
            },
            id: tourId
          }
        })

        refetch()
      } catch (e) {
        setUploadError(e)
      }
    } else {
      try {
        const result = await createTour({
          variables: {
            data: {
              price: parseFloat(submittedData.price as any),
              status: TourStatus.Draft,
              texts
            }
          }
        })

        tourId = result.data?.createTour.id
      } catch (e) {
        setUploadError(e)
      }
    }

    if (
      ((submittedData.keyvisual as any)?.id !== data?.tour.keyvisual?.id) ||
      (!data?.tour.keyvisual && submittedData.keyvisual)
    ) {
      try {
        await uploadFile({
          file: submittedData.keyvisual,
          data: {
            key: 'keyvisual',
            replaceId: data?.tour.keyvisual?.id,
            model: 'Tour',
            modelId: tourId as string
          }
        })
      } catch (e) {
        setUploadError(e)
      }
    }

    if (!tour?.id) {
      navigate(`/tour/${tourId}`)
    }

    setShowSnackbar(true)
  }

  const onConfirmDelete = async () => {
    try {
      await deleteTour({
        variables: {
          id: tour!.id
        }
      })

      cache.evict({
        id: cache.identify({
          __typename: 'Tour',
          id: tour!.id
        })
      })

      navigate('/')
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    methods.reset(getDefaultValues())
  }, [tour])

  const addImage = (file: File | null) => {
    console.log('addImage', file)
    methods.setValue('keyvisual', file, { shouldDirty: true })
  }

  if (loading) {
    return <LinearProgress />
  }

  return <FormProvider {...methods}>
    <form
      style={{ display: 'flex', flexDirection: 'column', overflowY: 'hidden', flex: 1 }}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <Box
        p={4}
        flex={1}
        sx={{ overflowY: 'auto' }}
      >
        <Box display="flex">
          <Box display="flex" flexDirection="column" marginRight={12}>
            <Controller
              control={methods.control}
              name="status"
              render={({ field: { onChange, value } }) => (
                <Box mb="40px">
                  <InputLabel id="status-label">{t('common.status')}</InputLabel>
                  <Select
                    sx={{ width: '200px' }}
                    labelId="status-label"
                    value={value}
                    label="Age"
                    onChange={v => onChange(v)}
                  >
                    <MenuItem value={TourStatus.Draft}>{t(`edit.tour.status.${TourStatus.Draft}`)}</MenuItem>
                    <MenuItem value={TourStatus.Private}>{t(`edit.tour.status.${TourStatus.Private}`)}</MenuItem>
                    <MenuItem value={TourStatus.Published}>{t(`edit.tour.status.${TourStatus.Published}`)}</MenuItem>
                  </Select>
                </Box>
              )}
            />
            <TextField
              error={!!methods.formState.errors.texts?.title}
              sx={{ marginBottom: '40px', width: '500px' }}
              label={t('common.title')}
              helperText={!!methods.formState.errors.texts?.title && t('common.required')}
              InputLabelProps={{ shrink: true }}
              {...methods.register('texts.title', { required: true })}
            />
            <TextField
              error={!!methods.formState.errors.texts?.subtitle}
              sx={{ marginBottom: '40px', width: '500px' }}
              label={t('common.subtitle')}
              helperText={!!methods.formState.errors.texts?.subtitle && t('common.required')}
              InputLabelProps={{ shrink: true }}
              {...methods.register('texts.subtitle', { required: true })}
            />
            <Chip label={t('common.description')} sx={{ marginBottom: '16px' }} />
            <MarkdownEditor
              name={'texts.description'}
              width="500px"
              style={{ marginBottom: '60px' }}
            />
          </Box>
          <FileDragDrop
            accept={{ 'image/*': ['.png', '.jpg', '.jpeg', '.webp'] }}
            height="100%"
            width="100%"
            minHeight="500px"
            maxHeight="500px"
            preview
            style={{ marginBottom: '60px' }}
            onFileChanged={addImage}
            initialFile={methods.getValues().keyvisual}
          >
            <Typography>{ !tour?.keyvisual ? t('edit.poi.dragImage') : (tour.keyvisual as any).fileName }</Typography>
          </FileDragDrop>
        </Box>
      </Box>

      <ConfirmDialog
        open={showDeleteDialog}
        text={t('edit.tour.confirmDelete')}
        onConfirm={onConfirmDelete}
        onCancel={() => setShowDeleteDialog(false)}
      />

      <FormFooterBar
        disabled={!methods.formState.isDirty}
        loading={isUpdating || isCreating}
        uploadError={uploadError}
        showSnackbar={showSnackbar}
        closeSnackbar={() => setShowSnackbar(false)}
      >
        {tour && <>
          <LoadingButton
            color="warning"
            variant="outlined"
            loading={isDeleting}
            endIcon={<DeleteIcon />}
            onClick={() => setShowDeleteDialog(true)}
          >
            {t('edit.tour.delete')}
          </LoadingButton>
          <Box flex={1000} />
          <Button
            variant="outlined"
            sx={{ mr: 2 }}
            endIcon={<FileDownloadIcon />}
            onClick={() => openTourDownloadUrl()}
          >
            {t('edit.tour.export')}
          </Button>
        </>}
      </FormFooterBar>
    </form>
  </FormProvider>
}

export default TourEdit

import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { Box, Button, Card, Dialog, DialogActions, DialogContent, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { useImportTourMutation, useImportTranslationsMutation, useToursQuery } from '@typings/graphql'
import { TourCard } from '@shared/components/ui/TourCard'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import TranslationUploadIcon from '@mui/icons-material/Translate'
import { useNavigate } from 'react-router'
import FileDragDrop from '@features/cms/components/FileDragDrop'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import languages from '@assets/json/languages.json'

const TourList: React.FC = () => {
  const navigate = useNavigate()

  const { t } = useTranslation()
  const { data, refetch } = useToursQuery()
  const tours = data?.tours

  const [importTour, { loading }] = useImportTourMutation()
  const [importTranslations, { loading: loadingTranslations }] = useImportTranslationsMutation()
  const [tourItemOpenState, setTourItemOpenState] = useState<Record<string, boolean>>({})
  const [showImportDialog, setShowImportDialog] = useState<'tour' | 'translations' | false>(false)
  const [importFile, setImportFile] = useState<File | null>(null)
  const [importLanguage, setImportLanguage] = useState<string>('en')

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    tours?.forEach((tour) => { setTourItemOpenState({ ...tourItemOpenState, [tour.id]: false }) })
  }, [tours])

  const performTourImport = useCallback(async () => {
    if (!importFile) return

    await importTour({
      variables: { file: importFile },
      fetchPolicy: 'no-cache'
    })

    setShowImportDialog(false)
    alert('Tour imported')
  }, [importFile])

  const performTranslationsImport = useCallback(async () => {
    if (!importFile) return

    const { data: importResult } = await importTranslations({
      variables: {
        language: importLanguage,
        file: importFile
      },
      fetchPolicy: 'no-cache'
    })

    setShowImportDialog(false)

    alert(`Translations imported.
    
    Added: ${importResult?.importTranslations?.added}
    Updated: ${importResult?.importTranslations?.updated}
    Unchanged: ${importResult?.importTranslations?.unchanged}`)
  }, [importFile, importLanguage])

  const tourCards = useMemo(() => (tours || []).map((tour) => (
    <TourCard key={tour.id}
      tour={{
        keyvisual: tour.keyvisual?.url,
        title: tour.title,
        subtitle: tour.subtitle,
        rating: tour.rating
      }}
      onTourSelect={() => navigate(`/tour/${tour.id}`)}
    />
  )), [tours])

  const createTourCard = useMemo(() => (
    <Card
      key='create-tour'
      sx={{
        position: 'relative',
        height: '430px',
        aspectRatio: '2/3',
        cursor: 'pointer',
        borderRadius: '15px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      onClick={() => navigate('/tour/create')}
    >
      <AddCircleOutlineIcon />
      <Typography>{t('edit.tour.create')}</Typography>
    </Card>
  ), [])

  const importTourCard = useMemo(() => (
    <Card
      key='import-tour'
      sx={{
        position: 'relative',
        height: '430px',
        aspectRatio: '2/3',
        cursor: 'pointer',
        borderRadius: '15px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      onClick={() => setShowImportDialog('tour')}
    >
      <UploadFileIcon />
      <Typography>{t('tour.importTour')}</Typography>
    </Card>
  ), [])

  const importTranslationsCard = useMemo(() => (
    <Card
      key='import-translations'
      sx={{
        position: 'relative',
        height: '430px',
        aspectRatio: '2/3',
        cursor: 'pointer',
        borderRadius: '15px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      onClick={() => setShowImportDialog('translations')}
    >
      <TranslationUploadIcon />
      <Typography>{t('edit.tour.importTranslations')}</Typography>
      <Typography sx={{
        opacity: 0.5,
        fontSize: '0.8rem'
      }}
      >(*.json)</Typography>
    </Card>
  ), [])

  return (
    <Box
      height="100%"
      padding={6}
      display="flex"
      flexWrap="wrap"
      gap="20px"
      sx={{ overflowY: 'auto' }}
    >
      {tourCards.concat(createTourCard, importTourCard, importTranslationsCard)}

      <Dialog open={!!showImportDialog} PaperProps={{ sx: { maxWidth: '800px', width: '60vw' } }}>
        <DialogContent sx={{ width: '100%' }}>
          <Box>
            <Typography variant="h5">
              {showImportDialog === 'tour' ? t('tour.importTour') : t('edit.tour.importTranslations')}
            </Typography>
            <Box mt={2}>
              <InputLabel id="import-language">{t('common.language')}</InputLabel>
              <Select
                autoWidth
                labelId="import-language"
                defaultValue='en'
                maxRows={10}
                value={importLanguage}
                onChange={(e) => setImportLanguage(e.target.value as string)}
              >
                {languages.map((language) => (
                  <MenuItem key={language.code} value={language.code}>{language.name} ({language.code})</MenuItem>
                ))}
              </Select>
            </Box>
            <Box width="100%" mt={2}>
              <FileDragDrop
                accept={showImportDialog === 'tour' ? { 'application/zip': ['.zip'] } : { 'application/json': ['.json'] }}
                onFileChanged={setImportFile}
                maxFileSize={2000000000}
                height={100}
              />
            </Box>
          </Box>
        </DialogContent>

        <DialogActions sx={{ p: 4, pt: 0 }}>
          <Button
            variant="outlined"
            type="button"
            disabled={loading}
            onClick={() => setShowImportDialog(false)}
          >
            {t('common.cancel')}
          </Button>

          <LoadingButton
            variant="contained"
            type="button"
            disabled={!importFile || loading}
            loading={loading || loadingTranslations}
            onClick={showImportDialog === 'tour' ? performTourImport : performTranslationsImport}
          >
            {t('common.save')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default TourList

import React from 'react'
import { Box, AppBar, Toolbar, IconButton, MenuItem, Select } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import Logo from '@assets/images/logo2.png'
import { useNavigate } from 'react-router'
import { useAppDispatch, useAppSelector } from '@services/store'
import { resetAuthData } from '@services/store/slices/auth'
import { useLogoutMutation } from '@typings/graphql'
import { AllBreadcrumbs } from '@shared/components/ui/Breadcrumbs'
import languages from '@assets/json/languages.json'
import { setLanguage } from '@services/store/slices/cms'

export const NavBar: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const language = useAppSelector((state) => state.cms.language)

  const [logout] = useLogoutMutation()

  const performLogout = async () => {
    await logout()
    dispatch(resetAuthData())
    navigate('/')
  }

  const updateLanguage = (newLanguage: string) => {
    dispatch(setLanguage(newLanguage))

    setTimeout(() => {
      location.reload()
    }, 500)
  }

  const staging = import.meta.env.REACT_APP_API_ENDPOINT.includes('staging')

  return (
    <AppBar sx={{ flex: '0 0 64px', position: 'relative' }}>
      <Toolbar
        sx={{
          backgroundColor: staging ? '#a7ffae' : '#fff',
          display: 'flex',
          justifyContent: 'space-between',
          gap: 4
        }}
      >
        <Box display="flex" alignItems="center" flex={1}>
          <img
            src={Logo}
            onClick={() => navigate('/', { replace: true })}
            style={{ cursor: 'pointer', height: '15px' }}
          />
          <AllBreadcrumbs />
        </Box>

        <Select
          autoWidth
          labelId="import-language"
          value={language}
          onChange={(e) => updateLanguage(e.target.value as string)}
          size='small'
        >
          {languages.map((language) => (
            <MenuItem key={language.code} value={language.code}>{language.name} ({language.code})</MenuItem>
          ))}
        </Select>

        <IconButton
          size="large"
          edge="start"
          sx={{
            color: '#000',
            '&:hover': { color: '#8159F3' },
            cursor: 'pointer'
          }}
          onClick={performLogout}
        >
          <LogoutIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

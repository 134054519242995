import { createSlice } from '@reduxjs/toolkit'

type CmsState = {
  modeMapZoom: Record<string, number>
  modeMapStyle: Record<string, string>
  modePoiView: Record<string, 'list' | 'map'>

  language: string
}

const slice = createSlice({
  name: 'cms',
  initialState: {
    modeMapZoom: {},
    modeMapStyle: {},
    modePoiView: {},

    language: 'de'
  } as CmsState,
  reducers: {
    setMapViewState: (state, { payload }) => {
      state.modeMapZoom = {
        ...state.modeMapZoom,
        ...payload
      }
    },
    setMapStyle: (state, { payload }) => {
      state.modeMapStyle = {
        ...state.modeMapStyle,
        ...payload
      }
    },
    setPoiView: (state, { payload }) => {
      state.modePoiView = {
        ...state.modePoiView,
        ...payload
      }
    },
    setLanguage: (state, { payload }) => {
      state.language = payload
    }
  }
})

export const {
  setMapViewState,
  setMapStyle,
  setPoiView,
  setLanguage
} = slice.actions

export default slice.reducer
